//
// Implement a component that shows the front page image and the menu
//

import { Outlet } from 'react-router-dom';
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CookieBanner from "./CookieBanner";
import AdBanner from './AdBanner';

import front_img from './front.jpg';
import front_img_2 from './aas_front-1.jpg';

//
// This is the common layout for all pages
//
function Layout(props) {
  //
  // Dark mode is handled by using the Outlet context
  //
  const [dark, setDark] = React.useState(false);

  const handleChange = (event) => {
    setDark(event.target.checked);
    console.log("dark mode: " + event.target.checked);
  }

  var color = "w3-light-gray w3-text-black";
  var color2 = "buttoncolor w3-border-black";
  if (dark) {
    color = "w3-text-light-gray w3-black" // dark mode
    color2 = "buttoncolordark w3-border-gray";
  }

  //
  // Cookie consent
  //
  const navigate = useNavigate();

  const [consentReset, setConsentReset] = useState(0);

  function resetCookieConsent() {
    console.log("reset Cookie Consent");    
    localStorage.removeItem("cookie_consent");
    localStorage.removeItem("cookie_consent_date");
    setConsentReset(consentReset + 1);
    navigate("/");
  }

  return (
    <div className={color}>
      <div className="w3-content" style={{maxWidth: "1100px"}}>

        <div className="w3-display-container w3-center"
              style={{maxWidth: "1100px"}}>
          <img className="w3-image w3-hide-small" width="100%"
                src={front_img} alt="Two human-like AIs staring each other" />
          <img className="w3-image w3-hide-large w3-hide-medium" width="100%"
                src={front_img_2} alt="Two human-like AIs staring each other" />
          <div className="w3-large w3-display-topright neonText" style={{top: "10px", right: "10px"}}>
          Dark mode &nbsp;
            <label className="switch">
              <input type="checkbox" onClick={handleChange}></input>
              <span className="slider round"></span>
            </label>
          </div>

          <Link to="/">
            <div className="w3-display-topmiddle" style={{top: "3em"}}>
              <div className="w3-hide-large w3-large neonText" style={{whiteSpace: "nowrap"}}><b><code>ai-and-science.com</code></b></div>
              <div className="w3-hide-large neonText" style={{whiteSpace: "nowrap"}}><b>Technology with AI and Science</b></div>
            </div>            
            <div className="w3-display-middle">
              <div className="">
                <div className="w3-hide-medium w3-hide-small w3-xxlarge neonText"><b><code>ai-and-science.com</code></b></div>
                <div className="w3-hide-medium w3-hide-small w3-xlarge neonText"><b>Technology with AI and Science</b></div>
              </div>
            </div>
          </Link>

          <nav>          
            <div className={"w3-bar w3-round w3-display-bottommiddle w3-hide-small "} style={{bottom: "4px"}}>
              <Link to="/" className={"w3-bar-item w3-button w3-border w3-round-large " + color2}>Home</Link>
              <Link to="/latest" className={"w3-bar-item w3-button w3-border w3-round-large " + color2}>Latest</Link>
              <Link to="/trending" className={"w3-bar-item w3-button w3-border w3-round-large " + color2}>Trending</Link>
              <Link to="/mostread" className={"w3-bar-item w3-button w3-border w3-round-large " + color2}>Most Read</Link>
              <Link to="/search" className={"w3-bar-item w3-button w3-border w3-round-large " + color2}><i className="fa-solid fa-magnifying-glass"></i></Link>
            </div>

            <div className={"w3-center w3-padding-16 w3-hide-large w3-hide-medium " + color}>
              <div className={"w3-bar " + color}>
                <Link to="/" className="w3-bar-item w3-button w3-border w3-small">Home</Link>
                <Link to="/latest" className="w3-bar-item w3-button w3-border w3-small">Latest</Link>
                <Link to="/trending" className="w3-bar-item w3-button w3-border w3-small">Trending</Link>
                <Link to="/mostread" className="w3-bar-item w3-button w3-border w3-small">Most Read</Link>
                <Link to="/search" className="w3-bar-item w3-button w3-border w3-small"><i className="fa-solid fa-magnifying-glass"></i></Link>
              </div>
            </div>
          </nav>

        </div>

         {/* <AdBanner /> */} 

        <Outlet context={[dark, setDark]} />

      </div>

      <footer className="w3-black">
        <div className="w3-row">
          <div className="w3-col w3-third w3-container">
            <Link to="/editorial-policy">Editorial Policy</Link> <br />
            <Link to="/privacy-policy">Privacy Policy</Link> <br />
            <Link to="/cookie-policy">Cookie Policy</Link>
          </div>
          <div className="w3-col w3-third w3-container">
            <div className="w3-button w3-light-grey" onClick={() => {window.scrollTo(0, 0);}}>
              &#9650; To the top &#9650; 
            </div>
          </div>
          <div className="w3-col w3-third w3-container w3-right">
            <Link to="/about">About</Link> <br />
            <a href="mailto:contact@ai-and-science.com"><i className="fa-regular fa-envelope"></i> contact@ai-and-science.com</a><br />
            <Link onClick={resetCookieConsent}><i className="fa-solid fa-rotate-right"></i>  Reset Cookie Consent</Link><br />
            </div>
        </div>
      </footer>

      <CookieBanner reset={consentReset} />

    </div>
  );
}
  
export default Layout;